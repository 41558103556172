import React from 'react';
import { DataAuthorizationRolesList } from '../../models/DataAuthorizationRolesAggregate/DataAuthorizationRole';
import { RequestStatus, API_REQUEST_STATUS } from '../../utils/APIHandlers';

export interface DataAuthorizationRolesContextProps {
  getDataAuthorizationRoles: () => Promise<void>;
  dataAuthorizationRolesData: DataAuthorizationRolesList;
  requestDataAuthorizationRolesStatus: RequestStatus;
}

export const defaultDataAuthorizationRolesValue: DataAuthorizationRolesContextProps = {
  getDataAuthorizationRoles: async () => new Promise(() => void 0),
  dataAuthorizationRolesData: [],
  requestDataAuthorizationRolesStatus: API_REQUEST_STATUS.NOT_REQUESTED,
};

const dataAuthorizationRolesContext = React.createContext<DataAuthorizationRolesContextProps>(
  defaultDataAuthorizationRolesValue
);

export { dataAuthorizationRolesContext };
