import { PetUserApiDTO } from '../../../api/rest/models/Users';
import { PetUserViewModel } from '../../../models/PetUsersAggregate/PetUser';
import {
  hasDataAuthorizationManagementAccess,
  hasFeatureAccessManagementAccess,
  hasUserManagementAccess,
} from '../../../pages/Administration/helper';
import { PetUsersSortingParams } from '../../../shared/store/PetUser/petUsersContext';
import { UserContextProps } from '../../../shared/store/userContext';
import { getTranslation } from '../../../utils/getTranslation';
import { compareSortingFields, isValueIncludesSearch } from '../../../utils/viewHelper';
import { PetUsersTableHeaderType, petUsersTableHeader } from './PetUserTable/constants';

export const getDeletePetUserMessage = (isErrorDelete: boolean, user: PetUserViewModel | null): string => {
  const userName = (user && `${user.firstName} ${user.lastName}`) || '';

  return isErrorDelete
    ? `${getTranslation('Error')} ${getTranslation('RetryDeleteWarningContent')} ${userName}?`
    : `${getTranslation('DeleteWarningContent')} ${userName}?`;
};
export const getDeletePetUserControl = (isErrorDelete: boolean): string =>
  getTranslation(isErrorDelete ? 'RetryDeleteNow' : 'Delete');

export const getToggleEnablePetUserMessage = (
  isErrorUpdate: boolean,
  currentState: boolean | undefined = false
): string => {
  const titleRetryKey = currentState ? 'RetryDisableNow' : 'RetryEnableNow';
  const titleKey = currentState ? 'DisableWarningContent' : 'EnableWarningContent';

  return isErrorUpdate
    ? `${getTranslation('Error')} ${getTranslation(titleRetryKey)}?`
    : getTranslation(titleKey);
};

export const getToggleEnablePetUserTitle = (currentState?: boolean): string =>
  getTranslation(typeof currentState === 'boolean' && currentState ? 'Disabled' : 'Enabled');

export const getToggleEnablePetUserControl = (
  isErrorUpdate: boolean,
  currentState: boolean | undefined = false
): string => {
  const controlRetryKey = currentState ? 'RetryDisableNow' : 'RetryEnableNow';
  const controlKey = currentState ? 'Disabled' : 'Enabled';

  return getTranslation(isErrorUpdate ? controlRetryKey : controlKey);
};

export const getFilteredPetUsers = (data: PetUserApiDTO[], searchString: string): PetUserApiDTO[] =>
  data.filter((user) =>
    [
      user.firstName,
      user.lastName,
      user.email,
      user.dataAuthRole?.roleName ?? '',
      user.featureAccessGroup?.groupName ?? '',
    ].some((item) => isValueIncludesSearch(item, searchString))
  );

export const comparePetUserFields = (
  leftValue: PetUserApiDTO,
  rightValue: PetUserApiDTO,
  sortParams: PetUsersSortingParams
): number => {
  switch (sortParams.sortBy) {
    case 'firstName':
    case 'email':
    case 'isEnabled':
    case 'isFederated': {
      return compareSortingFields(
        sortParams.sortOrder,
        leftValue[sortParams.sortBy],
        rightValue[sortParams.sortBy]
      );
    }
    case 'dataAuthRole': {
      return compareSortingFields(
        sortParams.sortOrder,
        leftValue[sortParams.sortBy]?.roleName.toLowerCase() ?? '',
        rightValue[sortParams.sortBy]?.roleName.toLowerCase() ?? ''
      );
    }
    case 'featureAccessGroup': {
      return compareSortingFields(
        sortParams.sortOrder,
        leftValue[sortParams.sortBy]?.groupName?.toLowerCase() ?? '',
        rightValue[sortParams.sortBy]?.groupName?.toLowerCase() ?? ''
      );
    }
    default:
      return 0;
  }
};

export const isLeaveCondition = ({
  isDirty,
  redirectUrlToLeave,
  requestCondition,
}: {
  isDirty: boolean;
  redirectUrlToLeave: string;
  requestCondition: boolean;
}): boolean => !isDirty || redirectUrlToLeave.length > 0 || (isDirty && requestCondition);

export const hasDataAccess = (field: keyof PetUserApiDTO, userContextValue: UserContextProps): boolean => {
  switch (field) {
    case 'dataAuthRole':
      return hasDataAuthorizationManagementAccess(userContextValue);
    case 'featureAccessGroup':
      return hasFeatureAccessManagementAccess(userContextValue);
    default:
      return hasUserManagementAccess(userContextValue);
  }
};

export const getUnavailablePetUsersFields = (userContextValue: UserContextProps): PetUsersTableHeaderType[] =>
  petUsersTableHeader.filter((item) => !hasDataAccess(item.field, userContextValue));

export const hasUnavailableFields = (userContextValue: UserContextProps): boolean =>
  !hasFeatureAccessManagementAccess(userContextValue) ||
  !hasDataAuthorizationManagementAccess(userContextValue);
