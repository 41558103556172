import {
  CreateDataAuthorizationRole,
  DataAuthorizationRole,
  UpdateDataAuthorizationRole,
} from '../models/DataAuthorizationRolesAggregate/DataAuthorizationRole';
import { PaginatedResponse } from '../api/graphql/models/common';
import { APIFetch } from '../utils/APIHandlers';
import { ErrorFetchResponse, RestApiResponse } from '../api/rest/models/RestApiResponse';
import { PetApi } from '../constants/apiParams';
import { handleError } from '../utils/errorHandler';

export class DataAuthorizationRolesService {
  private readonly endpoint: string;

  constructor() {
    this.endpoint = '/v1/tickets/pet/dataAuthorizationRoles';
  }

  public async getAll(
    limit: number | null = null,
    offset: number | null = null,
    name: string | null = null
  ): Promise<RestApiResponse<PaginatedResponse<DataAuthorizationRole>> | ErrorFetchResponse> {
    let route = `${this.endpoint}/all`;

    const params = new URLSearchParams();

    if (limit !== null && offset !== null) {
      params.append('limit', limit.toString());
      params.append('offset', offset.toString());
    }

    if (name !== null) {
      params.append('name', name);
    }

    const queryString = params.toString();
    if (queryString) {
      route += `?${queryString}`;
    }

    try {
      return await APIFetch<RestApiResponse<PaginatedResponse<DataAuthorizationRole>>>(
        'get',
        PetApi,
        route,
        {}
      );
    } catch (error: any) {
      return handleError(error);
    }
  }

  public async create(
    role: CreateDataAuthorizationRole
  ): Promise<RestApiResponse<DataAuthorizationRole> | ErrorFetchResponse> {
    try {
      return await APIFetch<RestApiResponse<DataAuthorizationRole>>('post', PetApi, this.endpoint, {
        body: role,
      });
    } catch (error: any) {
      return handleError(error);
    }
  }

  public async update(
    role: UpdateDataAuthorizationRole
  ): Promise<RestApiResponse<DataAuthorizationRole> | ErrorFetchResponse> {
    try {
      return await APIFetch<RestApiResponse<DataAuthorizationRole>>('put', PetApi, `${this.endpoint}`, {
        body: role,
      });
    } catch (error: any) {
      return handleError(error);
    }
  }
}
