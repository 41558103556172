import { getTranslation } from '../../../utils/getTranslation';

export const maxCellLength = 20;
export const DEBOUNCE_INPUT_DELAY = 500;

export const formNamePccsValue = 'pccs.values';
export const formNamePccsIsInclusive = 'pccs.isInclusive';
export const formNameGcnsValue = 'gcns.values';
export const formNameGcnsInclusive = 'gcns.isInclusive';
export const formNameSmidsValue = 'smids.values';
export const formNameSmidsIsInclusive = 'smids.isInclusive';
export const formNameLcnsValue = 'lcns.values';
export const formNameLcnsIsInclusive = 'lcns.isInclusive';
export const formNameRoleName = 'roleName';

export const emptyDataAuthorizationRoles = {
  id: '',
  roleName: '',
  smids: {
    values: [],
    isInclusive: true,
  },
  lcns: {
    values: [],
    isInclusive: true,
  },
  gcns: {
    values: [],
    isInclusive: true,
  },
  pccs: {
    values: [],
    isInclusive: true,
  },
};

export const dataAuthorizationTableHeaders = (): string[] => [
  getTranslation('DataAuthorizationRoles_RoleName'),
  getTranslation('GlobalCustomerNumber'),
  getTranslation('SecurityManagerID'),
  getTranslation('LocalCustomerNumber'),
  getTranslation('PCC'),
];
export const dataAuthorizationTableAdditionalHeaders = [''];
