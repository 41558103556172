import React, { useState } from 'react';

import { WithApolloClient } from '../../../features/WithApolloClient/WithApolloClient';
import { API_REQUEST_CODE, API_REQUEST_STATUS, RequestStatus } from '../../../utils/APIHandlers';
import { PetUserViewModel } from '../../../models/PetUsersAggregate/PetUser';
import { PetUsersService } from '../../../services/PetUsersService';
import { mapPetUser } from '../../../features/AdministrationContainer/PetUsers/petUsersMapper';
import {
  defaultPetUserContextValue,
  PetUserContextProps,
  petUserContext,
} from '../../store/PetUser/petUserContext';
import { RestApiResponse } from '../../../api/rest/models/RestApiResponse';
import { PetUserApiDTO } from '../../../api/rest/models/Users';

interface Props {
  children: React.ReactChild;
}

const PetUser: React.FC<Props> = ({ children }) => {
  const [petUser, setPetUser] = useState<PetUserViewModel | null>(defaultPetUserContextValue.petUser);
  const [requestPetUserStatus, setRequestPetUserStatus] = useState<RequestStatus>(
    API_REQUEST_STATUS.NOT_REQUESTED
  );

  const getPetUser = async (id: string) => {
    const userService: PetUsersService = new PetUsersService();
    setRequestPetUserStatus(API_REQUEST_STATUS.REQUESTED);
    setPetUser(null);

    const result = await userService.getById(id);
    const response = result as RestApiResponse<PetUserApiDTO>;

    if (response?.code === API_REQUEST_CODE.SUCCESS) {
      setRequestPetUserStatus(API_REQUEST_STATUS.REQUEST_SUCCESS);
      setPetUser(mapPetUser(response.result));
    } else {
      console.error(result);
      setRequestPetUserStatus(API_REQUEST_STATUS.REQUEST_FAILURE);
    }
  };

  const getPetUserContext = (): PetUserContextProps => {
    return {
      ...defaultPetUserContextValue,
      getPetUser,
      petUser,
      requestPetUserStatus,
    };
  };

  return <petUserContext.Provider value={getPetUserContext()}>{children}</petUserContext.Provider>;
};

const WithPetUser = WithApolloClient(PetUser);

export { WithPetUser };
