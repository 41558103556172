import { CreatePetUserDTO, UpdatePetUserDTO, PetUserApiDTO } from '../api/rest/models/Users';
import { PetApi } from '../constants/apiParams';
import { ErrorFetchResponse, RestApiResponse } from '../api/rest/models/RestApiResponse';
import { APIFetch } from '../utils/APIHandlers';
import { handleError } from '../utils/errorHandler';

export class PetUsersService {
  private readonly endpoint: string;

  constructor() {
    this.endpoint = '/v1/tickets/pet/users';
  }

  public async getAll(): Promise<RestApiResponse<PetUserApiDTO[]> | ErrorFetchResponse> {
    try {
      return await APIFetch<RestApiResponse<PetUserApiDTO[]>>('get', PetApi, `${this.endpoint}/all`, {});
    } catch (error: any) {
      return handleError(error);
    }
  }

  public async getById(id: string): Promise<RestApiResponse<PetUserApiDTO> | ErrorFetchResponse> {
    try {
      return await APIFetch<RestApiResponse<PetUserApiDTO>>('get', PetApi, `${this.endpoint}/${id}`, {});
    } catch (error: any) {
      return handleError(error);
    }
  }

  public async getCurrent(): Promise<RestApiResponse<PetUserApiDTO> | ErrorFetchResponse> {
    try {
      return await APIFetch<RestApiResponse<PetUserApiDTO>>('get', PetApi, `${this.endpoint}/current`, {});
    } catch (error: any) {
      return handleError(error);
    }
  }

  public async create(
    user: CreatePetUserDTO
  ): Promise<RestApiResponse<CreatePetUserDTO> | ErrorFetchResponse> {
    return await APIFetch<RestApiResponse<CreatePetUserDTO>>('post', PetApi, this.endpoint, {
      body: user,
    });
  }

  public async updateUser(
    user: UpdatePetUserDTO
  ): Promise<RestApiResponse<UpdatePetUserDTO> | ErrorFetchResponse> {
    return await APIFetch<RestApiResponse<UpdatePetUserDTO>>('put', PetApi, this.endpoint, {
      body: user,
    });
  }

  public async deleteUser(id: string): Promise<RestApiResponse | ErrorFetchResponse> {
    return await APIFetch<RestApiResponse>('del', PetApi, `${this.endpoint}/${id}`, {});
  }
}
