import React, { useContext, useState } from 'react';

import { WithApolloClient } from '../../../features/WithApolloClient/WithApolloClient';
import { userContext } from '../../store/userContext';
import { PetUserFormViewModel, PetUserViewModel } from '../../../models/PetUsersAggregate/PetUser';
import { PetUsersService } from '../../../services/PetUsersService';
import {
  defaultUpdatePetUserContextValue,
  UpdatePetUserContextProps,
  updatePetUserContext,
} from '../../store/PetUser/updatePetUserContext';
import { mapUpdatePetUser } from '../../../features/AdministrationContainer/PetUsers/petUsersMapper';
import { useNotification, useNotificationReturn } from '../../hooks/notificationHook';
import { getTranslation } from '../../../utils/getTranslation';
import { getFetchErrorMessage } from '../../../utils/errorHandler';
import { ErrorFetchResponse } from '../../../api/rest/models/RestApiResponse';

interface Props {
  children: React.ReactChild;
}

const UpdatePetUser: React.FC<Props> = ({ children }) => {
  const { email } = useContext(userContext);
  const [petUserToDelete, setPetUserToDelete] = useState<PetUserViewModel | null>(null);
  const [petUserToUpdate, setPetUserToUpdate] = useState<PetUserViewModel | null>(null);
  const [requestUpdatePetUserMessage, setRequestUpdatePetUserMessage] = useState<string>(
    defaultUpdatePetUserContextValue.requestUpdatePetUserMessage
  );
  const [
    { notificationStatus: requestUpdatePetUserStatus },
    {
      setNotRequestedNotificationStatus: setNotRequestedUpdatePetUser,
      setRequestedNotificationStatus: setRequestedUpdatePetUser,
      setSuccessNotificationStatus: setSuccessUpdatePetUser,
      setFailNotificationStatus: setFailUpdatePetUser,
    },
  ]: useNotificationReturn = useNotification();
  const [requestDeletePetUserMessage, setRequestDeletePetUserMessage] = useState<string>(
    defaultUpdatePetUserContextValue.requestDeletePetUserMessage
  );
  const [
    { notificationStatus: requestDeletePetUserStatus },
    {
      setNotRequestedNotificationStatus: setNotRequestedDeletePetUser,
      setRequestedNotificationStatus: setRequestedDeletePetUser,
      setSuccessNotificationStatus: setSuccessDeletePetUser,
      setFailNotificationStatus: setFailDeletePetUser,
    },
  ]: useNotificationReturn = useNotification();

  const updatePetUser = async (data: PetUserFormViewModel): Promise<string | undefined | void> => {
    try {
      const userService: PetUsersService = new PetUsersService();
      setRequestedUpdatePetUser();

      await userService.updateUser(mapUpdatePetUser(data, email));

      setSuccessUpdatePetUser();
      setRequestUpdatePetUserMessage(
        `${getTranslation('PetUsers_User')} ${data.firstName} ${data.lastName} ${getTranslation(
          'successEditMessage'
        )}`
      );
    } catch (error) {
      const errorMessage = getFetchErrorMessage(error as ErrorFetchResponse);
      setFailUpdatePetUser();

      console.error(errorMessage);
      return errorMessage;
    }
  };

  const deletePetUser = async (data: PetUserViewModel): Promise<void> => {
    try {
      const userService: PetUsersService = new PetUsersService();
      setRequestedDeletePetUser();

      await userService.deleteUser(data.id);

      setSuccessDeletePetUser();
      setRequestDeletePetUserMessage(
        `${getTranslation('PetUsers_User')} ${data.firstName} ${data.lastName} ${getTranslation(
          'deleteMessage'
        )}`
      );
    } catch (error) {
      const errorMessage = getFetchErrorMessage(error as ErrorFetchResponse);
      setFailDeletePetUser();

      console.error(errorMessage);
    }
  };

  const clearUpdatePetUserState = () => {
    setNotRequestedUpdatePetUser();
    setRequestUpdatePetUserMessage(defaultUpdatePetUserContextValue.requestUpdatePetUserMessage);
  };

  const clearPetUserToDelete = () => {
    setPetUserToDelete(null);
    setNotRequestedDeletePetUser();
    setRequestDeletePetUserMessage(defaultUpdatePetUserContextValue.requestUpdatePetUserMessage);
  };

  const clearPetUserToUpdate = () => {
    setPetUserToUpdate(null);
    setNotRequestedUpdatePetUser();
    setRequestUpdatePetUserMessage(defaultUpdatePetUserContextValue.requestUpdatePetUserMessage);
  };

  const getUpdatePetUserContext = (): UpdatePetUserContextProps => {
    return {
      ...defaultUpdatePetUserContextValue,
      updatePetUser,
      requestUpdatePetUserMessage,
      requestUpdatePetUserStatus,
      clearUpdatePetUserState,
      deletePetUser,
      requestDeletePetUserMessage,
      requestDeletePetUserStatus,

      setPetUserToDelete,
      clearPetUserToDelete,
      petUserToDelete,

      setPetUserToUpdate,
      clearPetUserToUpdate,
      petUserToUpdate,
    };
  };

  return (
    <updatePetUserContext.Provider value={getUpdatePetUserContext()}>
      {children}
    </updatePetUserContext.Provider>
  );
};

const WithUpdatePetUser = WithApolloClient(UpdatePetUser);

export { WithUpdatePetUser };
