import React, { Dispatch, ReactElement, SetStateAction, useState, useCallback } from 'react';
import { Prompt, useHistory } from 'react-router-dom';
import { Location as HistoryLocation } from 'history';
import WarningModal from '../WarningModal';
import { getTranslation } from '../../utils/getTranslation';
import { useForm, useFormState } from 'react-hook-form';
import { Button, Form } from 'semantic-ui-react';

interface Props<T> {
  children: React.ReactElement<ReactElement, string | React.JSXElementConstructor<ReactElement>>;
  cancelButtonClicked: Dispatch<SetStateAction<boolean>>;
  editFormData?: T;
  submitForm: (data: T) => Promise<string | undefined | void>;
  isEditForm?: boolean;
  formClass?: string;
  warningMessage?: string;
  textActionButtonWarningModal?: string;
  textActionButton?: string;
  textSecondButton?: string;
  onClearHandler?: () => void;
  isClearBtn?: boolean;
  checkFormValidation?: () => void;
  dataTestId?: string;
  isLeaveCondition?: (isDirty: boolean, redirectUrlToLeave: string) => boolean;
  isRedirectLeave?: boolean;
  isNotEnoughAccess?: boolean;
  isSubmitDisabled?: boolean;
}

const PetForm = <T,>(props: Props<T>): ReactElement => {
  const {
    children,
    editFormData,
    cancelButtonClicked,
    submitForm,
    isEditForm,
    formClass,
    warningMessage,
    textActionButtonWarningModal,
    textActionButton,
    isClearBtn,
    textSecondButton,
    dataTestId,
    isLeaveCondition,
    isRedirectLeave = false,
    isNotEnoughAccess,
    isSubmitDisabled,
  } = props;

  const [formData, setFormData] = useState<T>(editFormData as T);
  const [redirectUrlToLeave, setRedirectUrlToLeave] = useState('');
  const [isSavePopupOpen, setIsSavePopupOpen] = useState(false);
  const [isLeavePopupOpen, setIsLeavePopupOpen] = useState(false);
  const [formError, setFormError] = useState<string | undefined>('');

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    watch,
    clearErrors,
    setValue,
    getValues,
    reset,
  } = useForm({
    defaultValues: { ...editFormData },
  });

  const { isDirty } = useFormState({
    control,
  });
  const handleBlockNavigation = (nextLocation: HistoryLocation): boolean => {
    setRedirectUrlToLeave(nextLocation.pathname);
    if (isLeaveCondition?.(isDirty, redirectUrlToLeave) ?? (!isDirty || redirectUrlToLeave)) {
      return true;
    } else {
      setIsLeavePopupOpen(!isLeavePopupOpen);
      return false;
    }
  };

  const history = useHistory();

  const onCancelClicked = (isSuccess?: boolean): void => {
    if (isRedirectLeave && (isSuccess || isSuccess === undefined)) {
      cancelButtonClicked(false);
    } else if (isRedirectLeave && !isSuccess) {
      setIsLeavePopupOpen(false);
      setRedirectUrlToLeave('');
    } else if (isSuccess || !isDirty) {
      cancelButtonClicked(false);
      if (isSuccess && redirectUrlToLeave) {
        history.push(redirectUrlToLeave);
      }
    } else {
      setIsLeavePopupOpen(!isLeavePopupOpen);
      setRedirectUrlToLeave(redirectUrlToLeave ?? '');
    }
  };

  const handleSubmitForm = useCallback(async () => {
    const savedFormData = {
      ...editFormData,
      ...formData,
    };

    setIsSavePopupOpen(false);

    const submitError = await submitForm(savedFormData);

    if (typeof submitError === 'string') {
      setFormError(submitError);
    } else {
      reset();
    }
  }, [submitForm, reset, formData, editFormData]);

  const onSaveHandler = (isSuccess?: boolean): void => {
    if (isSuccess) {
      void handleSubmitForm();
    } else {
      setIsSavePopupOpen(false);
    }
  };

  const onSubmitClicked = (submitData: { [key: string]: string | boolean | number }) => {
    const commonFormError = props.checkFormValidation?.();
    if (commonFormError) {
      setFormError(commonFormError);
      return;
    }

    const trimValueFormData = {} as {
      [key: string]: string | number | boolean;
    };

    Object.keys(submitData).forEach((key) => {
      trimValueFormData[key] =
        typeof submitData[key] === 'string' ? (submitData[key] as string).trim() : submitData[key];
    });

    setFormData(trimValueFormData as unknown as T);
    setIsSavePopupOpen(true);
  };

  const onClearClicked = () => {
    reset();
    setFormError('');
    props.onClearHandler?.();
  };

  const onSubmitForm = useCallback(
    (event: React.FormEvent<HTMLFormElement>): void => {
      void handleSubmit(onSubmitClicked)(event);
    },
    [onSubmitClicked]
  );

  return (
    <>
      <Prompt when={true} message={handleBlockNavigation} />
      <Form onSubmit={onSubmitForm} className={formClass} data-testid={dataTestId}>
        {React.cloneElement(children, {
          ...{
            formError,
            errors,
            handleSubmit,
            control,
            registerForm: register,
            watch,
            clearErrors,
            setValue,
            getValues,
            onCancelClicked,
            setFormError,
          },
          ...children.props,
        })}

        {!!formError && (
          <Form.Field>
            <div className="formError">{formError}</div>
          </Form.Field>
        )}

        <Form.Group inline className="button-container">
          <Button
            basic
            type="button"
            size="small"
            color="blue"
            onClick={isClearBtn ? onClearClicked : () => onCancelClicked()}
            data-testid="cancel-petForm-btn"
          >
            {textSecondButton || getTranslation('Cancel')}
          </Button>
          <Button
            primary
            data-testid="submit-petForm-btn"
            type="submit"
            size="small"
            disabled={!!isSubmitDisabled || (isEditForm && !isDirty) || isNotEnoughAccess}
          >
            {textActionButton || getTranslation('Save')}
          </Button>
        </Form.Group>
      </Form>
      <WarningModal
        isOpen={isLeavePopupOpen}
        titleText={getTranslation('Cancel')}
        contentText={getTranslation('CancelWarningContent')}
        onClose={onCancelClicked}
        textActionButton={getTranslation('Yes')}
      />
      <WarningModal
        isOpen={isSavePopupOpen}
        titleText={getTranslation('SaveWarningTitle')}
        contentText={warningMessage || getTranslation('SaveWarningContent')}
        onClose={onSaveHandler}
        textActionButton={textActionButtonWarningModal || getTranslation('Save')}
        isSaveBtn={true}
      />
    </>
  );
};

export default PetForm;
