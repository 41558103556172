import React, { useEffect, useState } from 'react';

import { WithApolloClient } from '../../../features/WithApolloClient/WithApolloClient';
import {
  defaultPetUsersContextValue,
  PetUsersContextProps,
  petUsersContext,
  PetUsersSortingParams,
} from '../../store/PetUser/petUsersContext';
import { API_REQUEST_CODE, API_REQUEST_STATUS, RequestStatus } from '../../../utils/APIHandlers';
import { countItemsPerPage, DEFAULT_PAGE } from '../../../pages/Administration/constant';
import { PetUserViewModel } from '../../../models/PetUsersAggregate/PetUser';
import { PetUsersService } from '../../../services/PetUsersService';
import { mapPetUsers } from '../../../features/AdministrationContainer/PetUsers/petUsersMapper';
import { PetUserApiDTO } from '../../../api/rest/models/Users';
import {
  comparePetUserFields,
  getFilteredPetUsers,
} from '../../../features/AdministrationContainer/PetUsers/helper';
import { getItemsRange } from '../../../utils/viewHelper';
import { RestApiResponse } from '../../../api/rest/models/RestApiResponse';

interface Props {
  children: React.ReactChild;
}

const PetUsers: React.FC<Props> = ({ children }) => {
  const [searchString, setSearchString] = useState<string>(defaultPetUsersContextValue.filterSearchString);
  const [sortParams, setSortParams] = useState<PetUsersSortingParams>(defaultPetUsersContextValue.sortParams);
  const [petUsersDTO, setPetUsersDTO] = useState<PetUserApiDTO[]>([]);
  const [petUsers, setPetUsers] = useState<PetUserViewModel[]>(defaultPetUsersContextValue.petUsers);
  const [totalPetUsers, setTotalPetUsers] = useState<number>(defaultPetUsersContextValue.totalPetUsers);
  const [activePagePetUsers, setActivePagePetUsers] = useState<number>(
    defaultPetUsersContextValue.activePagePetUsers
  );
  const [requestPetUsersStatus, setRequestPetUsersStatus] = useState<RequestStatus>(
    defaultPetUsersContextValue.requestPetUsersStatus
  );

  const getPetUsers = async () => {
    const userService: PetUsersService = new PetUsersService();
    setRequestPetUsersStatus(API_REQUEST_STATUS.REQUESTED);

    const result = await userService.getAll();
    const response = result as RestApiResponse<PetUserApiDTO[]>;

    if (response?.code === API_REQUEST_CODE.SUCCESS) {
      setRequestPetUsersStatus(API_REQUEST_STATUS.REQUEST_SUCCESS);
      setPetUsersDTO(response.result);
      setTotalPetUsers(response.result.length);
      handleSetPetUsers(DEFAULT_PAGE, response.result, sortParams);
    } else {
      console.error(result);
      setRequestPetUsersStatus(API_REQUEST_STATUS.REQUEST_FAILURE);
    }
  };

  const handleSetPetUsers = (page: number, data: PetUserApiDTO[], sortParams: PetUsersSortingParams) => {
    const nextPetUsers = getFilteredPetUsers(data, searchString);
    nextPetUsers.sort((leftValue, rightValue) => comparePetUserFields(leftValue, rightValue, sortParams));
    const { start, end } = getItemsRange(page, countItemsPerPage);

    setActivePagePetUsers(page);
    setPetUsers(mapPetUsers(nextPetUsers.slice(start, end)));
    setTotalPetUsers(nextPetUsers.length);
  };

  const setPetUsersActivePage = (page: number) => {
    handleSetPetUsers(page, petUsersDTO, sortParams);
  };

  useEffect(() => {
    handleSetPetUsers(DEFAULT_PAGE, petUsersDTO, sortParams);
  }, [searchString]);

  useEffect(() => {
    handleSetPetUsers(activePagePetUsers, petUsersDTO, sortParams);
  }, [sortParams]);

  const getPetUsersContext = (): PetUsersContextProps => {
    return {
      ...defaultPetUsersContextValue,
      getPetUsers,
      petUsers,
      totalPetUsers,
      activePagePetUsers,
      requestPetUsersStatus,
      setPetUsersActivePage,
      filterPetUsers: setSearchString,
      filterSearchString: searchString,
      sortPetUsers: setSortParams,
      sortParams,
    };
  };

  return <petUsersContext.Provider value={getPetUsersContext()}>{children}</petUsersContext.Provider>;
};

const WithPetUsers = WithApolloClient(PetUsers);

export { WithPetUsers };
