import { PetUserApiDTO } from '../api/rest/models/Users';
import { PetUsersSortingParams } from '../shared/store/PetUser/petUsersContext';
import { MIN_SEARCH_STRING_LENGTH, DESCENDING_VALUE, ASCENDING_VALUE } from './constants';

type Descending = 'desc';
type Ascending = 'asc';
export type SortOrder = Ascending | Descending;

export const nextFieldSortOrder = (
  field: keyof PetUserApiDTO,
  sortParams: PetUsersSortingParams
): SortOrder => {
  if (sortParams.sortBy === field) {
    return sortParams.sortOrder === ASCENDING_VALUE ? DESCENDING_VALUE : ASCENDING_VALUE;
  } else {
    return ASCENDING_VALUE;
  }
};
export const sortIconDirection = (field: keyof PetUserApiDTO, sortParams: PetUsersSortingParams): string => {
  if (sortParams.sortBy === field) {
    return sortParams.sortOrder === ASCENDING_VALUE ? 'up' : 'down';
  } else {
    return 'up';
  }
};

export const getItemsRange = (page: number, countPerPage: number): { start: number; end: number } => ({
  start: (page - 1) * countPerPage,
  end: countPerPage * page,
});

export const compareSortingFields = (
  sortOrder: SortOrder,
  leftValue: string | boolean,
  rightValue: string | boolean
): number => {
  const validLeftValue = typeof leftValue === 'string' ? leftValue.toLowerCase() : leftValue;
  const validRightValue = typeof rightValue === 'string' ? rightValue.toLowerCase() : rightValue;

  const isEqual = validLeftValue === validRightValue || typeof leftValue !== typeof rightValue;
  const comparingResult =
    sortOrder === ASCENDING_VALUE ? validLeftValue > validRightValue : validLeftValue < validRightValue;
  const comparingValue = comparingResult ? 1 : -1;

  return isEqual ? 0 : comparingValue;
};

export const isValueIncludesSearch = (value: string, search: string): boolean =>
  value.toLowerCase().includes(search.toLowerCase());

export const isFilteredValueIncludesSearch = (value: string, search: string): boolean =>
  isValueIncludesSearch(value, search) && search.length >= MIN_SEARCH_STRING_LENGTH;
