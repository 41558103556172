import React, { useState } from 'react';

import { WithApolloClient } from '../../features/WithApolloClient/WithApolloClient';
import {
  defaultDataAuthorizationRolesValue,
  dataAuthorizationRolesContext,
  DataAuthorizationRolesContextProps,
} from '../store/dataAuthorizationRolesContext';
import { API_REQUEST_CODE, API_REQUEST_STATUS, RequestStatus } from '../../utils/APIHandlers';
import {
  DataAuthorizationRole,
  DataAuthorizationRolesList,
} from '../../models/DataAuthorizationRolesAggregate/DataAuthorizationRole';
import { DataAuthorizationRolesService } from '../../services/DataAuthorizationRolesService';
import { RestApiResponse } from '../../api/rest/models/RestApiResponse';
import { PaginatedResponse } from '../../api/graphql/models/common';

interface Props {
  children: React.ReactChild;
}

const DataAuthorizationRoles: React.FC<Props> = ({ children }) => {
  const [dataAuthorizationRolesData, setDataAuthorizationRolesData] = useState<DataAuthorizationRolesList>(
    []
  );
  const [requestDataAuthorizationRolesStatus, setRequestDataAuthorizationRolesStatus] =
    useState<RequestStatus>(API_REQUEST_STATUS.NOT_REQUESTED);

  const getDataAuthorizationRoles = async () => {
    const dataAuthorizationRolesService: DataAuthorizationRolesService = new DataAuthorizationRolesService();
    setRequestDataAuthorizationRolesStatus(API_REQUEST_STATUS.REQUESTED);
    const result = await dataAuthorizationRolesService.getAll();
    const response = result as RestApiResponse<PaginatedResponse<DataAuthorizationRole>>;

    if (response?.code === API_REQUEST_CODE.SUCCESS) {
      setRequestDataAuthorizationRolesStatus(API_REQUEST_STATUS.REQUEST_SUCCESS);
      setDataAuthorizationRolesData(response.result.data || []);
    } else {
      console.error(result);
      setRequestDataAuthorizationRolesStatus(API_REQUEST_STATUS.REQUEST_FAILURE);
    }
  };

  const getDataAuthorizationRolesContext = (): DataAuthorizationRolesContextProps => {
    return {
      ...defaultDataAuthorizationRolesValue,
      getDataAuthorizationRoles,
      dataAuthorizationRolesData,
      requestDataAuthorizationRolesStatus,
    };
  };

  return (
    <dataAuthorizationRolesContext.Provider value={getDataAuthorizationRolesContext()}>
      {children}
    </dataAuthorizationRolesContext.Provider>
  );
};

const WithDataAuthorizationRoles = WithApolloClient(DataAuthorizationRoles);

export { WithDataAuthorizationRoles };
