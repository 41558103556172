import React, { Dispatch, SetStateAction, useState, useCallback } from 'react';
import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import { Button, Divider, Grid, Header, Icon, Radio } from 'semantic-ui-react';
import { Controller } from 'react-hook-form';
import { FieldError } from 'react-hook-form/dist/types';
import { UseFormRegister } from 'react-hook-form/dist/types/form';

import { WithApolloClient } from './../../../WithApolloClient/WithApolloClient';
import { getTranslation } from './../../../../utils/getTranslation';
import { mapArrayToDropdownOption } from './helper';
import DateInfo from './../../../PetForm/DateInfo';
import { DropdownViewModel } from './../../../../models/DropdownViewModel';
import { getRequiredFieldRegister, handleAdditionOptionFactory } from './../../../PetForm/helper';
import { DataAuthorizationRole } from './../../../../models/DataAuthorizationRolesAggregate/DataAuthorizationRole';
import InputWithInfo from './../../../PetForm/InputWithInfo';
import DropdownWithInfo from './../../../PetForm/DropdownWithInfo';
import {
  formNamePccsValue,
  formNamePccsIsInclusive,
  formNameGcnsValue,
  formNameGcnsInclusive,
  formNameSmidsValue,
  formNameSmidsIsInclusive,
  formNameLcnsValue,
  formNameLcnsIsInclusive,
  formNameRoleName,
} from './../constants';
import { FormProps } from './../../../PetForm/types';

import './styles.scss';
import './../../../PetForm/commonFormStyles.scss';

interface DataAuthorizationRolesFormProps
  extends FormProps<DataAuthorizationRole, UseFormRegister<DataAuthorizationRole>> {
  client?: ApolloClient<NormalizedCacheObject>;
  errors?: {
    roleName: FieldError;
  };
}

const DataAuthorizationRolesForm: React.FC<DataAuthorizationRolesFormProps> = (props) => {
  const {
    editFormData: { smids, lcns, gcns, pccs, updatedDate, updatedBy, createdDate, createdBy, id },
    errors,
    control,
    registerForm,
    onCancelClicked,
    setValue,
  } = props;

  const isEditForm = Boolean(id);
  const requiredFieldRegister = getRequiredFieldRegister();

  const [smidsValue, setSmidsValue] = useState<string[]>(smids?.values || []);
  const [smidsOptions, setSmidsOptions] = useState<DropdownViewModel[]>(
    mapArrayToDropdownOption(smids?.values)
  );
  const [lcnsValue, setLcnsValue] = useState<string[]>(lcns?.values || []);
  const [lcnsOptions, setLcnsOptions] = useState<DropdownViewModel[]>(mapArrayToDropdownOption(lcns?.values));
  const [gcnsValue, setGcnsValue] = useState<string[]>(gcns?.values || []);
  const [gcnsOptions, setGcnsOptions] = useState<DropdownViewModel[]>(mapArrayToDropdownOption(gcns?.values));
  const [pccsValue, setPccsValue] = useState<string[]>(pccs?.values || []);
  const [pccsOptions, setPccsOptions] = useState<DropdownViewModel[]>(mapArrayToDropdownOption(pccs?.values));

  const handleDropdownStateChange = (
    value: string[],
    setDropdownValue: Dispatch<SetStateAction<string[]>>,
    onChange: (value: string[]) => void
  ): void => {
    setDropdownValue?.(value);

    onChange(value);
  };

  const handleCancel = useCallback((): void => {
    onCancelClicked?.();
  }, [onCancelClicked]);

  return (
    <>
      <div className="form-header-container">
        <div>
          <Header as="h1" data-testid="dataAuthorizationRolesForm-title">
            {isEditForm
              ? getTranslation('DataAuthorizationRolesForm_EditDataAuthorization')
              : getTranslation('DataAuthorizationRolesForm_CreateDataAuthorization')}
          </Header>
        </div>
        <Button basic color="blue" size="small" onClick={handleCancel} data-testid="returnToTheTable-control">
          <Icon name="arrow alternate circle left" /> {getTranslation('ReturnToTheTable')}
        </Button>
      </div>

      <div className="form-container">
        <Grid>
          <Grid.Row>
            <Grid.Column width={4}>
              <InputWithInfo
                required
                label={getTranslation('DataAuthorizationRolesForm_RoleName')}
                error={errors?.roleName}
                register={registerForm?.(formNameRoleName, requiredFieldRegister)}
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </div>

      <div className="form-container">
        <div className="accordion">
          <input type="checkbox" id="question-toggle" />
          <label htmlFor="question-toggle" className="headline">
            <Icon name="dropdown" />
            {getTranslation('DataAuthorizationRoles_HowDoesItWork')} <Icon name="info circle" />
          </label>
          <div className="text">
            {getTranslation('DataAuthorizationRoles_HowDoesItWorkTextPart1')}
            <br />
            {getTranslation('DataAuthorizationRoles_HowDoesItWorkTextPart2')}
            <br />
            <br />
            {getTranslation('DataAuthorizationRoles_HowDoesItWorkTextPart3')}
          </div>
        </div>

        <Grid className="container-dataAuthorizationRoleForm">
          <Grid.Row verticalAlign="top">
            <Grid.Column className="container-dataAuthorizationRoleFormField">
              <Controller
                render={({ field: { onChange, value } }) => (
                  <DropdownWithInfo
                    label={getTranslation('GlobalCustomerNumber')}
                    options={gcnsOptions}
                    onChange={(_e, { value }) =>
                      handleDropdownStateChange(value as string[], setGcnsValue, onChange)
                    }
                    value={value as string[]}
                    onAddItem={handleAdditionOptionFactory({
                      value: gcnsValue,
                      setValue: setGcnsValue,
                      setFormValue: setValue,
                      setFormValueName: formNameGcnsValue,
                      setOptions: setGcnsOptions,
                      options: gcnsOptions,
                    })}
                    isSearched={true}
                    isAllowAdditions
                    isMultiple={true}
                    isSelection={true}
                  />
                )}
                name={formNameGcnsValue}
                control={control}
              />
            </Grid.Column>
            <Grid.Column className="container-dataAuthorizationRoleFormControl">
              <Controller
                render={({ field: { onChange } }) => (
                  <Radio
                    toggle
                    label={getTranslation('DataAuthorizationRoles_Inclusive')}
                    defaultChecked={gcns?.isInclusive}
                    onChange={(_e, { checked }) => onChange(Boolean(checked))}
                  />
                )}
                name={formNameGcnsInclusive}
                control={control}
              />
            </Grid.Column>
          </Grid.Row>

          <Divider />

          <Grid.Row verticalAlign="top">
            <Grid.Column className="container-dataAuthorizationRoleFormField">
              <Controller
                render={({ field: { onChange, value } }) => (
                  <DropdownWithInfo
                    label={getTranslation('SecurityManagerID')}
                    options={smidsOptions}
                    onChange={(_e, { value }) =>
                      handleDropdownStateChange(value as string[], setSmidsValue, onChange)
                    }
                    value={value as string[]}
                    onAddItem={handleAdditionOptionFactory({
                      value: smidsValue,
                      setValue: setSmidsValue,
                      setFormValue: setValue,
                      setFormValueName: formNameSmidsValue,
                      setOptions: setSmidsOptions,
                      options: smidsOptions,
                    })}
                    isSearched={true}
                    isAllowAdditions
                    isMultiple={true}
                    isSelection={true}
                  />
                )}
                name={formNameSmidsValue}
                control={control}
              />
            </Grid.Column>
            <Grid.Column className="container-dataAuthorizationRoleFormControl">
              <Controller
                render={({ field: { onChange } }) => (
                  <Radio
                    toggle
                    label={getTranslation('DataAuthorizationRoles_Inclusive')}
                    defaultChecked={smids?.isInclusive}
                    onChange={(_e, { checked }) => onChange(Boolean(checked))}
                  />
                )}
                name={formNameSmidsIsInclusive}
                control={control}
              />
            </Grid.Column>
          </Grid.Row>

          <Divider />

          <Grid.Row verticalAlign="bottom">
            <Grid.Column className="container-dataAuthorizationRoleFormField">
              <Controller
                render={({ field: { onChange, value } }) => (
                  <DropdownWithInfo
                    label={getTranslation('LocalCustomerNumber')}
                    options={lcnsOptions}
                    onChange={(_e, { value }) =>
                      handleDropdownStateChange(value as string[], setLcnsValue, onChange)
                    }
                    value={value as string[]}
                    onAddItem={handleAdditionOptionFactory({
                      value: lcnsValue,
                      setValue: setLcnsValue,
                      setFormValue: setValue,
                      setFormValueName: formNameLcnsValue,
                      setOptions: setLcnsOptions,
                      options: lcnsOptions,
                    })}
                    isSearched={true}
                    isAllowAdditions
                    isMultiple={true}
                    isSelection={true}
                  />
                )}
                name={formNameLcnsValue}
                control={control}
              />
            </Grid.Column>
            <Grid.Column className="container-dataAuthorizationRoleFormControl">
              <Controller
                render={({ field: { onChange } }) => (
                  <Radio
                    toggle
                    label={getTranslation('DataAuthorizationRoles_Inclusive')}
                    defaultChecked={lcns?.isInclusive}
                    onChange={(_e, { checked }) => onChange(Boolean(checked))}
                  />
                )}
                name={formNameLcnsIsInclusive}
                control={control}
              />
            </Grid.Column>
          </Grid.Row>

          <Divider />

          <Grid.Row verticalAlign="bottom">
            <Grid.Column className="container-dataAuthorizationRoleFormField">
              <Controller
                render={({ field: { onChange, value } }) => (
                  <DropdownWithInfo
                    label={getTranslation('PCC')}
                    options={pccsOptions}
                    onChange={(_e, { value }) =>
                      handleDropdownStateChange(value as string[], setPccsValue, onChange)
                    }
                    value={value as string[]}
                    onAddItem={handleAdditionOptionFactory({
                      value: pccsValue,
                      setValue: setPccsValue,
                      setFormValue: setValue,
                      setFormValueName: formNamePccsValue,
                      setOptions: setPccsOptions,
                      options: pccsOptions,
                    })}
                    isSearched={true}
                    isAllowAdditions
                    isMultiple={true}
                    isSelection={true}
                  />
                )}
                name={formNamePccsValue}
                control={control}
              />
            </Grid.Column>
            <Grid.Column className="container-dataAuthorizationRoleFormControl">
              <Controller
                render={({ field: { onChange } }) => (
                  <Radio
                    toggle
                    label={getTranslation('DataAuthorizationRoles_Inclusive')}
                    defaultChecked={pccs?.isInclusive}
                    onChange={(_e, { checked }) => onChange(Boolean(checked))}
                  />
                )}
                name={formNamePccsIsInclusive}
                control={control}
              />
            </Grid.Column>
          </Grid.Row>
          {isEditForm && (
            <>
              <Divider />

              <Grid.Row>
                <Grid.Column>
                  <div data-testid="dataAuthorizationRoles-dates">
                    <DateInfo
                      createdDate={createdDate}
                      createdBy={createdBy}
                      updatedDate={updatedDate}
                      updatedBy={updatedBy}
                    />
                  </div>
                </Grid.Column>
              </Grid.Row>
            </>
          )}
        </Grid>
      </div>
    </>
  );
};

export default WithApolloClient<DataAuthorizationRolesFormProps>(DataAuthorizationRolesForm);
