import { DataAuthorizationRole } from '../../../models/DataAuthorizationRolesAggregate/DataAuthorizationRole';
import { DropdownViewModel } from '../../../models/DropdownViewModel';
import { FeatureAccessGroupViewModel } from '../../../models/FeatureAccessGroupsAggregate/FeatureAccessGroup';

export const mapDataAuthorizationRolesOptions = (data: DataAuthorizationRole[]): DropdownViewModel[] =>
  data.map(
    (item: DataAuthorizationRole, index: number): DropdownViewModel => ({
      key: String(index),
      value: item.id,
      text: item.roleName,
    })
  );

export const mapFeatureAccessGroupsOptions = (data: FeatureAccessGroupViewModel[]): DropdownViewModel[] =>
  data.map(
    (item: FeatureAccessGroupViewModel, index: number): DropdownViewModel => ({
      key: String(index),
      value: item.id ?? '',
      text: item.groupName,
    })
  );
