import React, { useState } from 'react';
import { ErrorFetchResponse, RestApiResponse } from '../../../api/rest/models/RestApiResponse';
import { ReportSettingsService } from '../../../services/ReportSettingsService';
import { dynamicReportContext } from './dynamicReportContext';
import { ActionResult, ReportConfigurationModel, TypedActionResult } from './types';
import { API_REQUEST_CODE } from '../../../utils/APIHandlers';

interface Props {
  children: React.ReactChild;
  reportId: number;
}

const WithDynamicReportPage: React.FC<Props> = ({ children, reportId }) => {
  const reportSettingsService = new ReportSettingsService();
  const [allReportConfigurations, setAllReportConfigurations] = useState<ReportConfigurationModel[]>([]);
  const [activeConfiguration, setActiveConfiguration] = useState<ReportConfigurationModel | null>(null);

  const updateByResponse = (
    response: RestApiResponse<ReportConfigurationModel> | ErrorFetchResponse
  ): void => {
    const apiResponse = response as RestApiResponse<ReportConfigurationModel>;

    if (apiResponse?.code == API_REQUEST_CODE.SUCCESS) {
      setActiveConfiguration(apiResponse.result);

      let result = [];

      if (!allReportConfigurations.some((x) => x.id === apiResponse.result.id)) {
        result = [...allReportConfigurations, apiResponse.result];
      } else {
        result = allReportConfigurations.map((x) =>
          x.id === apiResponse.result.id ? apiResponse.result : x
        );
      }

      sortReportConfigurations(result);
      setAllReportConfigurations(result);
    }
  };

  function toActionResult<T>(response: RestApiResponse<T> | ErrorFetchResponse): ActionResult {
    const statusCode = (response as RestApiResponse<ReportConfigurationModel>)?.code ?? -1;

    switch (statusCode) {
      case 200:
      case 204:
        return ActionResult.Ok;
      case 409:
        return ActionResult.AlreadyExists;
      default:
        return ActionResult.UnknownError;
    }
  }

  const get = async (): Promise<TypedActionResult<ReportConfigurationModel[]>> => {
    const response = await reportSettingsService.get(reportId);

    const data = (response as RestApiResponse<ReportConfigurationModel[]>)?.result ?? [];
    sortReportConfigurations(data);
    setAllReportConfigurations(data);

    const latestReportConfiguration = getLatestReportConfiguration(data);
    setActiveConfiguration(latestReportConfiguration);

    return {
      result: toActionResult(response),
      data: data,
    };
  };

  const create = async (
    name: string,
    fields: string[],
    gcns: string[],
    smids: string[]
  ): Promise<ActionResult> => {
    const response = await reportSettingsService.create({
      name: name,
      reportId: reportId,
      settings: {
        fields,
        gcns,
        smids,
      },
    });

    updateByResponse(response);
    return toActionResult(response);
  };

  const update = async (fields: string[], gcns: string[], smids: string[]): Promise<ActionResult> => {
    if (!activeConfiguration) {
      return ActionResult.UnknownError;
    }

    const requestModel = {
      ...activeConfiguration,
      settings: {
        fields,
        gcns,
        smids,
      },
    };

    const response = await reportSettingsService.update(requestModel);

    updateByResponse(response);
    return toActionResult(response);
  };

  const rename = async (value: string): Promise<ActionResult> => {
    if (!activeConfiguration) {
      return ActionResult.UnknownError;
    }

    const requestModel = {
      ...activeConfiguration,
      name: value,
    };

    const response = await reportSettingsService.update(requestModel);

    updateByResponse(response);
    return toActionResult(response);
  };

  const del = async (): Promise<ActionResult> => {
    if (!activeConfiguration) {
      return ActionResult.UnknownError;
    }

    const response = await reportSettingsService.delete(activeConfiguration.id);
    const apiResponse = response as RestApiResponse<ReportConfigurationModel>;

    if (apiResponse?.code === 204) {
      const result = allReportConfigurations.filter((x) => x.id !== activeConfiguration.id);
      setAllReportConfigurations(result);

      setActiveConfiguration(null);
    }

    return toActionResult(response);
  };

  const getAllReportConfigurations = (): ReportConfigurationModel[] => allReportConfigurations;

  const getActiveReportConfiguration = (): ReportConfigurationModel | null => activeConfiguration;

  const setActiveReportConfiguration = (id: string): void => {
    const configuration = allReportConfigurations.find((x) => x.id === id) ?? null;

    setActiveConfiguration(configuration);
  };

  const sortReportConfigurations = (reportConfigurations: ReportConfigurationModel[]): void => {
    reportConfigurations.sort((a, b) => a.name.toLocaleLowerCase().localeCompare(b.name.toLocaleLowerCase()));
  };

  const getLatestReportConfiguration = (
    reportConfigurations: ReportConfigurationModel[]
  ): ReportConfigurationModel | null => {
    if (reportConfigurations.length === 0) {
      return null;
    }

    return reportConfigurations.reduce(
      (prev, current) => (prev.lastModifiedDate > current.lastModifiedDate ? prev : current),
      reportConfigurations[0]
    );
  };

  return (
    <dynamicReportContext.Provider
      value={{
        get,
        create,
        update,
        rename,
        del,
        getAllReportConfigurations,
        getActiveReportConfiguration,
        setActiveReportConfiguration,
      }}
    >
      {children}
    </dynamicReportContext.Provider>
  );
};

export { WithDynamicReportPage };
