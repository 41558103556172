import { PetUserApiDTO } from '../../../../api/rest/models/Users';

export type PetUsersTableHeaderType = {
  field: keyof PetUserApiDTO;
  title: string;
};

export const petUsersTableHeader: PetUsersTableHeaderType[] = [
  {
    field: 'firstName',
    title: 'PetUsers_Name',
  },
  {
    field: 'email',
    title: 'PetUsers_Email',
  },
  {
    field: 'dataAuthRole',
    title: 'DataAuthorizationRole',
  },
  {
    field: 'featureAccessGroup',
    title: 'FeatureGroup',
  },
  {
    field: 'isEnabled',
    title: 'PetUsers_Enabled',
  },
  {
    field: 'isFederated',
    title: 'PetUsers_Federated',
  },
];
