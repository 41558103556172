import { DropdownViewModel } from '../../models/DropdownViewModel';
import { getTranslation } from '../../utils/getTranslation';
import { Dispatch, SetStateAction, SyntheticEvent } from 'react';
import { FieldValues, UseFormSetValue } from 'react-hook-form';

export const EMAIL_REGEX_STRING = /^[A-Z0-9._%+-]+@([A-Z0-9-]+\.)+[A-Z]{2,4}$/i;

export const mapResponseToDropdownOption = <T>({
  response,
  text,
  value,
}: {
  response: T[];
  text: keyof T;
  value: keyof T;
}): DropdownViewModel[] => {
  return response.map((item, i) => {
    const valueRow = item[value];
    const valueCasted: string | number = typeof valueRow === 'number' ? valueRow : String(valueRow);

    return {
      key: String(valueRow) + i.toString(),
      value: valueCasted,
      text: String(item[text]),
    };
  });
};

export const getRequiredFieldRegister = (): { required: { value: boolean; message: string } } => ({
  required: {
    value: true,
    message: getTranslation('FieldRequired'),
  },
});

export const getEmailPattern = (): { pattern: { value: RegExp; message: string } } => ({
  pattern: {
    value: EMAIL_REGEX_STRING,
    message: getTranslation('EmailValidation'),
  },
});

export const getPasswordPattern = (): { pattern: { value: RegExp; message: string } } => ({
  pattern: {
    value: /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/,
    message: getTranslation('PasswordValidation'),
  },
});

export const generateNewOptionAndValue = ({
  stringValue,
  separator,
  validate,
}: {
  stringValue: string;
  separator?: RegExp;
  validate?: RegExp;
}): { newOptions: DropdownViewModel[]; newValues: string[]; invalidValues: string[] } => {
  const newOptions = [] as DropdownViewModel[];
  const newValues = [] as string[];
  const invalidValues = [] as string[];

  Array.from(new Set(stringValue.split(separator || ','))).forEach((value: string) => {
    const newValue = value.trim();

    if (newValue) {
      if (!validate || validate?.test(newValue)) {
        newOptions.push({ text: newValue, value: newValue, key: newValue });
        newValues.push(newValue);
      } else {
        invalidValues.push(newValue);
      }
    }
  });

  return { newOptions, newValues, invalidValues };
};

export const handleAdditionOptionFactory =
  ({
    value,
    setValue,
    setFormValue,
    setFormValueName,
    setOptions,
    options,
    separator,
    validate,
    limit,
    setInvalidateValue,
    setIsLimitExceedValue,
  }: {
    value: string[];
    setValue: Dispatch<SetStateAction<string[]>>;
    setFormValue?: UseFormSetValue<FieldValues>;
    setFormValueName: string;
    setOptions: Dispatch<SetStateAction<DropdownViewModel[]>>;
    options: DropdownViewModel[];
    separator?: RegExp;
    validate?: RegExp;
    limit?: number;
    setInvalidateValue?: Dispatch<SetStateAction<string[]>>;
    setIsLimitExceedValue?: Dispatch<SetStateAction<boolean>>;
  }) =>
  (e: SyntheticEvent, { value: stringValue }: { value: string }): void => {
    const { newOptions, newValues, invalidValues } = generateNewOptionAndValue({
      stringValue,
      separator,
      validate,
    });

    const updatedValues = [...value, ...newValues];
    const isLimitExceeded = limit ? updatedValues.length > limit : false;

    setValue(updatedValues);
    setInvalidateValue?.(invalidValues);
    setIsLimitExceedValue?.(isLimitExceeded);
    setFormValue?.(setFormValueName, updatedValues, { shouldValidate: true });
    setOptions([...options, ...newOptions]);
  };
