import React, { useContext, useEffect, useState } from 'react';
import { Button, Form, Icon, Input, Message } from 'semantic-ui-react';
import { debounce } from 'throttle-debounce';

import { DataAuthorizationRolesService } from './../../../services/DataAuthorizationRolesService';
import { countItemsPerPage } from './../../../pages/Administration/constant';
import AdministrationTable from './../../AdministrationTable';
import { DataAuthorizationRole } from './../../../models/DataAuthorizationRolesAggregate/DataAuthorizationRole';
import DropdownMenu from './../../AdministrationTable/DropdownMenu';
import { getTranslation } from './../../../utils/getTranslation';
import DataAuthTableCell from './DataAuthTableCell/DataAuthTableCell';
import {
  DEBOUNCE_INPUT_DELAY,
  dataAuthorizationTableAdditionalHeaders,
  dataAuthorizationTableHeaders,
  emptyDataAuthorizationRoles,
} from './constants';
import DataAuthorizationRolesForm from './DataAuthorizationRolesForm';
import { userContext } from './../../../shared/store/userContext';
import AuditInfo from './AuditInfo';
import PetForm from './../../PetForm';
import { AdministrationTableBodyColumns } from './../../AdministrationTable/AdministrationTable';
import FullSizeLoader from '../../../shared/view/Loader/FullSizeLoader';
import MessageWithControl from '../../../shared/controls/MessageWithControl';
import TextButton from '../../../shared/controls/TextButton';

import './index.scss';
import './../../../styles/abministration-tab.scss';
import { PaginatedResponse } from '../../../api/graphql/models/common';
import { RestApiResponse } from '../../../api/rest/models/RestApiResponse';
import { API_REQUEST_CODE } from '../../../utils/APIHandlers';

const DataAuthorizationRoles: React.FC = () => {
  const { isDataAuthorizationManagementReadAccess, isDataAuthorizationManagementReadWriteAccess } =
    useContext(userContext);
  const [isFormOpen, setIsFormOpen] = useState(false);
  const [isAllDataLoad, setIsAllDataLoad] = useState<boolean>(false);
  const [allDataAuthRoles, setAllDataAuthRoles] = useState<DataAuthorizationRole[]>([]);
  const [totalDataAuthRoles, setTotalDataAuthRoles] = useState<number>(0);
  const [activeTablePage, setActiveTablePage] = useState<number>(1);
  const [searchString, setSearchString] = useState<string | null>(null);
  const [currentDataRole, setCurrentDataRole] = useState<DataAuthorizationRole>(emptyDataAuthorizationRoles);
  const [message, setMessage] = useState('');
  const [isSuccessMessage, setIsSuccessMessage] = useState(false);
  const [isSpinnerShow, setIsSpinnerShow] = useState(false);
  const [isShowErrorDataMessage, setIsShowErrorDataMessage] = useState<boolean>(false);
  const [isAuditInfoOpen, setIsAuditInfoOpen] = useState<boolean>(false);

  const roleService = new DataAuthorizationRolesService();

  const handleGetDataAuthRolesByPage = async (customPage = 1, searchString: string | null = null) => {
    const result = await roleService.getAll(
      countItemsPerPage,
      (customPage - 1) * countItemsPerPage,
      searchString
    );
    const response = result as RestApiResponse<PaginatedResponse<DataAuthorizationRole>>;

    if (response?.code === API_REQUEST_CODE.SUCCESS) {
      setAllDataAuthRoles(response.result.data);
      setTotalDataAuthRoles(response.result.total);

      setIsAllDataLoad(true);
    } else {
      console.error(result);

      setIsAllDataLoad(true);
      setIsShowErrorDataMessage(true);
    }
  };

  const handleSearch = (roleName: string | null) => {
    setSearchString(roleName);
    setActiveTablePage(1);
  };

  const debounceHandleSearch = debounce(DEBOUNCE_INPUT_DELAY, (roleName: string | null) =>
    handleSearch(roleName)
  );

  useEffect(() => {
    void handleGetDataAuthRolesByPage(activeTablePage, searchString);
  }, [activeTablePage, searchString]);

  const onTablePageChange = (activePage: number) => {
    setActiveTablePage(activePage);
  };

  const submitForm = async (dataRole: DataAuthorizationRole): Promise<string | undefined> => {
    setIsSpinnerShow(true);

    const result = dataRole.id ? await roleService.update(dataRole) : await roleService.create(dataRole);
    const response = result as RestApiResponse<DataAuthorizationRole>;

    if (response?.code === API_REQUEST_CODE.SUCCESS) {
      setIsFormOpen(false);
      setMessage(
        `${getTranslation('DataAuthorizationRole')} ${dataRole.roleName} ${getTranslation(
          dataRole.id ? 'successEditMessage' : 'successMessage'
        )}`
      );
      setIsSpinnerShow(false);
      setIsSuccessMessage(true);
      setTimeout(() => {
        setIsSuccessMessage(false);
      }, 4000);

      void handleGetDataAuthRolesByPage(activeTablePage, searchString);
    } else {
      setIsSpinnerShow(false);

      return getErrorMessage(dataRole.roleName, response?.code);
    }
  };

  const openEditForm = (dataRole: DataAuthorizationRole) => {
    setIsAuditInfoOpen(false);
    setIsFormOpen(true);
    setCurrentDataRole(dataRole);
  };

  const createNewDataAuthRoles = () => {
    setCurrentDataRole(emptyDataAuthorizationRoles);
    setIsAuditInfoOpen(false);
    setIsFormOpen(true);
  };

  const openAuditInfo = (dataRole: DataAuthorizationRole) => {
    setCurrentDataRole(dataRole);
    setIsFormOpen(false);
    setIsAuditInfoOpen(true);
  };

  const closeAuditInfo = () => {
    setIsAuditInfoOpen(false);
  };

  const rowGenerate = (
    row: DataAuthorizationRole
  ): { columns: AdministrationTableBodyColumns; rowKey: string } => {
    const { roleName, smids, lcns, gcns, pccs } = row;

    const tableRow: AdministrationTableBodyColumns = {
      roleName: roleName,
      gcns: <DataAuthTableCell restrictionFilter={gcns} />,
      smids: <DataAuthTableCell restrictionFilter={smids} />,
      lcns: <DataAuthTableCell restrictionFilter={lcns} />,
      pccs: <DataAuthTableCell restrictionFilter={pccs} />,
    };

    if (isDataAuthorizationManagementReadWriteAccess) {
      tableRow['controls'] = (
        <DropdownMenu<DataAuthorizationRole>
          openEditForm={openEditForm}
          row={row}
          key={`${row.roleName}_controls`}
          dataTestId="additional-dataAuthorizationRole-control"
        />
      );
    }

    return { columns: tableRow, rowKey: row.id };
  };

  const tableHeaderGenerate = () => {
    return dataAuthorizationTableHeaders().concat(
      (isDataAuthorizationManagementReadWriteAccess && dataAuthorizationTableAdditionalHeaders) || []
    );
  };

  const getErrorMessage = (name: string, errorCode?: number): string => {
    if (errorCode === API_REQUEST_CODE.NOT_FOUND) {
      return `${getTranslation('DataAuthorizationRole')} ${name} ${getTranslation('NotFound')}`;
    }

    if (errorCode === API_REQUEST_CODE.CONFLICT) {
      return `${getTranslation('DataAuthorizationRole')} ${name} ${getTranslation('AlreadyExists')}`;
    }

    return getTranslation('CommonError');
  };

  return (
    <div
      className="dataAuthorizationRoles administration-tab"
      data-testid="administration-dataAuthorization-tab"
    >
      {isSuccessMessage && <Message success content={message} />}

      {isSpinnerShow && <FullSizeLoader />}

      <div className="buttons-container">
        <Form>
          <Input
            icon="search"
            size="small"
            placeholder={getTranslation('Search')}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              debounceHandleSearch(event?.target.value ?? null)
            }
            name="filterSearch"
            data-testid="dataAuthFilter-input"
          />
        </Form>

        {isAllDataLoad && !isFormOpen && isDataAuthorizationManagementReadWriteAccess && (
          <Button
            primary
            size="small"
            disabled={isFormOpen}
            onClick={createNewDataAuthRoles}
            data-testid="createDataAuthorizationRole-control"
          >
            <Icon name="plus circle" /> {getTranslation('DataAuthorizationRolesForm_CreateDataAuthorization')}
          </Button>
        )}
      </div>

      {isShowErrorDataMessage && (
        <Message error data-testid="errorResponseDataAuthRoles-wrapper">
          <MessageWithControl message={getTranslation('App_LoadingError')}>
            <TextButton
              text={getTranslation('Retry_Now')}
              onClick={() => void handleGetDataAuthRolesByPage()}
              dataTestId="errorResponsePetUsers-retry-control"
            />
          </MessageWithControl>
        </Message>
      )}
      <div className="administration-tab-container">
        <AdministrationTable<DataAuthorizationRole>
          onPageChange={onTablePageChange}
          isCompressedTable={isFormOpen || isAuditInfoOpen}
          isAllDataLoad={isAllDataLoad}
          allRows={allDataAuthRoles}
          totalItems={totalDataAuthRoles}
          countItemsPerPage={countItemsPerPage}
          activePage={activeTablePage}
          openAuditInfo={openAuditInfo}
          tableHeaders={tableHeaderGenerate()}
          rowGenerate={(row) => rowGenerate(row)}
          className={'right-column'}
          containerClassName={isFormOpen ? 'hidden' : ''}
          isLastColumnAutoWidth={isDataAuthorizationManagementReadAccess}
        />
        {isFormOpen && (
          <PetForm<DataAuthorizationRole>
            cancelButtonClicked={setIsFormOpen}
            editFormData={currentDataRole}
            submitForm={submitForm}
            key={currentDataRole?.roleName + 'form'}
            formClass={'DataAuthorizationRolesForm'}
          >
            <DataAuthorizationRolesForm editFormData={currentDataRole} />
          </PetForm>
        )}
        {isAuditInfoOpen && (
          <AuditInfo
            currentDataAuthorizationRole={currentDataRole}
            closeAuditInfo={closeAuditInfo}
            key={currentDataRole.roleName + 'auditInfo'}
          />
        )}
      </div>
    </div>
  );
};

export default DataAuthorizationRoles;
